/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

$mini-sidebar-width: 80px;
/*******************
Main sidebar
******************/

.mat-sidenav {
  width: 240px;
  padding-top: 0px;
}

#snav {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

  .mat-drawer-inner-container {
    overflow: hidden;
  }

  .mat-nav-list .mat-list-item {
    height: auto;

    &:hover {
      background: none;
    }

    .mat-list-item-content {
      display: block;
      padding: 0px 0px;

      &:hover {
        background: none;

        > a {
          color: $themecolor;
        }
      }
    }

    a {
      height: 45px;
      padding: 15px 10px;
      margin-bottom: 10px;
      align-items: center;
      display: block;
      font-size: 15px;
      white-space: nowrap;
      color: $sidebar-text;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      mat-icon:not(.dd-icon) {
        margin-right: 8px;
      }

      mat-icon {
        color: $sidebar-icons;
      }

      .dd-icon {
        font-size: 16px;
        width: 16px;
        transition: 0.2s ease-in;
        margin: 5px 0 5px 5px;
        height: 16px;
      }
    }

    &.selected > .mat-list-item-content > a {
      background: #2c3e50;
      border-radius: $radius;
      color: $white;

      mat-icon {
        color: $white;
      }

      .dd-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }

    &.selected .sub-item .mat-list-item-content a.selected {
      background: transparent;
      color: $inverse;
      font-weight: 500;
    }
  }

  .app-sidebar > .mat-nav-list > .mat-list-item > .mat-list-item-content {
    padding: 0px 15px;
  }

  .mat-nav-list .sub-item {
    display: none;
    margin-top: -15px;

    a {
      padding-left: 40px;
      height: 50px;
      margin-bottom: 0px;
    }

    .child-sub-item {
      display: none;
      margin-top: -15px;

      a {
        padding: 0 0 0 55px;
        height: 50px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
      }
    }
  }

  .saperator {
    cursor: text;
    font-size: 13px;
    margin-bottom: 15px;
    margin-top: 25px;
    font-weight: 500;
    white-space: nowrap;
  }

  .selected {
    .sub-item {
      display: block;

      .selected + {
        .child-sub-item {
          display: block;
        }
      }
    }
  }
}

/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 31px 0;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      top: 31px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-text {
    padding: 5px 0px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;

    > a {
      color: $white !important;
      width: 100%;
      padding: 6px 30px;
      background: rgba(0, 0, 0, 0.5);
      // display: block;
      white-space: nowrap;

      &:after {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
}

/*============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media (min-width: 1024px) {
  #snav.mat-sidenav.mat-drawer-opened ~ .mat-drawer-content {
    margin-left: 21% !important;
    margin-top: 10px;
    visibility: visible;
    width: 79%;
  }
}

#snav.mat-sidenav.mat-drawer-opened ~ .mat-drawer-content.minitoggle {
  margin-left: 0px !important;
}

.minisidebar #snav {
  .mat-drawer-backdrop {
    display: none;
  }

  &.mat-sidenav.mat-drawer-opened ~ .mat-drawer-content {
    margin-left: $mini-sidebar-width !important;
  }

  &.mat-sidenav {
    -webkit-transition:
      width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
      transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transition:
      width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1),
      transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  }

  &.mat-sidenav:not(:hover) {
    width: $mini-sidebar-width;

    .sub-menu,
    .dd-icon,
    .selected .sub-item {
      display: none;
      visibility: hidden;
    }

    .user-profile {
      .profile-img {
        margin-left: 15px;
      }

      .profile-text {
        visibility: hidden;
      }
    }

    .saperator span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 40px;
      display: block;
    }

    .mat-list-item > .mat-list-item-content > a {
      padding-right: 0;
      padding-left: 0;
      text-align: center;
    }

    .mat-list-item > .mat-list-item-content > a > span:not(.menu-badge),
    .mat-list-item > .mat-list-item-content > a > .menu-caret {
      display: none;
    }

    .mat-list-item > .mat-list-item-content > a > span.menu-badge {
      position: absolute;
      top: 10px;
      right: 16px;
    }

    .mat-list-item > .mat-list-item-content > a > .material-icons {
      width: $mini-sidebar-width !important;
      padding: 0;
      margin: 0;
    }
  }
}

[dir='rtl'] {
  .app-sidebar {
    display: block;
    overflow: hidden;
  }

  .minisidebar #snav.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0px !important;
    margin-right: $mini-sidebar-width !important;
  }
}

.sidebar-closed #snav {
  &.mat-sidenav ~ .mat-drawer-content {
    margin-left: 0px !important;
  }
}

@media (min-width: 1024px) {
  .minisidebar #snav.mat-sidenav ~ .minitoggle.mat-drawer-content {
    margin-left: 0 !important;
  }
}

.verticalMenu {
  transform: none;
  margin-left: 10px;
  margin-top: 20px;
  visibility: visible;
  width: 20%;
  // width: auto;
  height: 565px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 10px 60px rgb(0 0 0 / 10%);
  border-radius: 20px;
}
.mat-sidenav-content {
  margin-left: 0 !important;
}
.mat-sidenav {
  position: fixed !important;
}
.btn-css {
  padding: 15px 0;
  background: #ffffff !important;
  box-shadow: 0px 0px 20px 10px rgb(0 0 0 / 10%);
  border-radius: 15px;
  width: fit-content;
  display: none !important;
  margin-top: 1.25rem;
}
.side-button {
  display: none !important;
  width: fit-content !important;
}
.side-button:focus {
  outline: none !important;
}
@media (max-width: 1099px) {
  .side-button {
    display: block !important;
  }
  .btn-css {
    display: block !important;
  }
  .content-css {
    margin-top: 20px !important;
  }
}
@media (max-width: 900px) {
  .verticalMenu {
    width: 30% !important;
  }
}
@media (max-width: 567px) {
  .verticalMenu {
    width: 60% !important;
  }
  .btn-css {
    margin-top: 5px;
    padding: 12px 0;
  }
}
