/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: montserrat-thick;
  src: url(././assets/fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}
@font-face {
  font-family: montserrat;
  src: url(././assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: montserrat-normal;
  src: url(././assets/fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
}

.example-month-picker .mat-calendar-period-button {
  pointer-events: none;
}

.example-month-picker .mat-calendar-arrow {
  display: none;
}

::ng-deep button.mat-calendar-period-button {
  display: none;
}
.input-intl {
  height: 100%;
  border: none;
  background: whitesmoke;
  width: 100%;
}
.iti--allow-dropdown {
  height: 60px;
  border: none;
  width: 100%;
}
.iti__country-list {
  position: relative;
  border: none;
  width: 280px;
  overflow-x: hidden;
}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  // left: 15px !important;
}
.iti--allow-dropdown input,
.iti--allow-dropdown input[type='text'],
.iti--allow-dropdown input[type='tel'],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type='text'],
.iti--separate-dial-code input[type='tel'] {
  padding-right: 6px;
  padding-left: 85px !important;
  margin-left: 0;
}

.input-align {
  text-align: left !important;
  padding-left: 10px !important;
  height: 39px !important;
}
.text-font {
  font-size: 28px !important;
}
.btn-width {
  width: 100% !important;
  height: 50px !important;
}
.loginBoxPosition {
  position: relative;
  bottom: 39px;
}
.iti__selected-flag div {
  // display: none !important;
}
.iti__flag {
  // display: none;
}
.iti.separate-dial-code .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag {
  // width: 0px !important;
}
.iti .selected-dial-code {
  color: #ec3b83;
}
.mat-candidate-profile {
  width: inherit;
  max-width: 100% !important;
  height: 100vh;
}
.mat-candidate-profile .mat-dialog-container {
  height: auto !important;
}
.input-align {
  text-align: left !important;
  padding-left: 10px !important;
  height: 39px !important;
}
.input-space {
  text-align: left !important;
  height: 39px !important;
}
.text-font {
  font-size: 28px !important;
}
.btn-width {
  width: 100% !important;
  height: 50px !important;
}
.loginBoxPosition {
  position: relative;
  bottom: 39px;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 4px 0.75em 0 0.75em !important;
}
.mat-select-value {
  text-align: left;
}
.mat-select-placeholder {
  color: #818b9f !important;
  margin-left: 0 !important;
}
.myPanelClass3 {
  top: auto;
  bottom: 100%;
  margin-bottom: 8px;
}
.iti__selected-flag {
  height: 50px !important;
}
.input-intl {
  height: 50px !important;
  border-radius: 7px !important;
}
.iti--allow-dropdown {
  height: 50px !important;
}
.myPanelClass {
  margin-top: 35px !important;
}
.myPanelClass2 {
  position: absolute;
  margin-top: 35px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
}
.mat-datepicker-content-container {
  display: flex;
  flex-direction: column-reverse !important;
  justify-content: space-between;
}
.mat-calendar-controls {
  display: flex;
  margin: 0% calc(4.7142857143% - 16px) !important;
}
.mat-calendar-header {
  padding: 0px 8px 0 8px !important;
}
.mat-datepicker-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0px 0px 8px !important;
}
.mat-datepicker-actions button {
  padding-right: 0px !important;
}
.mat-datepicker-content .mat-calendar {
  width: 296px;
  height: auto !important;
}
:host ::ng-deep .candidate-data {
  max-width: 100% !important;
  margin: 0px !important;
}
:host ::ng-deep .modal {
  // z-index: 50000 !important;
}
.modal {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;
}
.modal-dialog {
  margin: 0 auto;
}
// .ck-editor__top {
//   margin-bottom: 265px !important;
//   margin-top: -285px !important;
// }
// .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
//   border-bottom-width: 1px;
// }
.fade {
  transition: opacity 0.15s linear;
  background: #80808070;
}
.reason-modal {
  display: flex;
  align-items: center;
  height: inherit;
}

.show-modal .modal {
  z-index: 998 !important;
}
.cdk-overlay-container {
  z-index: 1000 !important;
}
.bell-icon {
  line-height: 34px !important;
}
html {
  overflow: auto !important;
}
@media screen and (max-width: 768px) {
  .mat-candidate-profile .mat-dialog-container {
    border-radius: 0 !important;
  }
}

.ease-in {
  animation: easeIn 400ms ease-in;
}

@keyframes easeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ease-out {
  animation: easeOut 400ms ease-out;
}

@keyframes easeOut {
  100% {
    opacity: 0;
  }
  0% {
    opacity: 1;
  }
}
